import { Injectable } from '@angular/core';
import { Zeroconf } from '@awesome-cordova-plugins/zeroconf/ngx';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { FeedbackService, MsgType } from './feedback.service';

@Injectable({
  providedIn: 'root'
})
export class ZeroconfService {

  public zeroconfSever: {name?: string, domain?: string }[] = []
  
  public zeroConfSub: Subscription

  constructor(
    private feedback: FeedbackService,
    private zeroconf: Zeroconf,
    private platform: Platform 
  ) {  
  }    
   
  public subscribeZeroConf(){ 

    if(!this.platform.is("capacitor")) return

    this.zeroconf.registerAddressFamily = 'ipv4';
    this.zeroconf.watchAddressFamily = 'ipv4';

    this.zeroConfSub = this.zeroconf.watch('_Zeiterfassung._tcp.', 'local.').subscribe(result => {
      console.log(result);
      
      if(result.service.port != -1)
      if(result.service.txtRecord.domain){
        if(this.zeroconfSever.find(s => s.domain == result.service.txtRecord.domain))return 
        else{  
           this.zeroconfSever.push({  
            domain: result.service.txtRecord.domain, 
            name: result.service.txtRecord.name 
          }) 
        }     
      }   
    })     
  }   
 
  public unsubscribeZeroConf(){ 
    this.zeroconfSever = []
    this.zeroConfSub?.unsubscribe()
  }
  
}  
          