import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'stempel',
    loadChildren: () => import('./pages/stempel/stempel.module').then( m => m.StempelPageModule)
  },
  {
    path: 'auswahl',
    loadChildren: () => import('./pages/auswahl/auswahl.module').then( m => m.AuswahlPageModule)
  },
  {
    path: "settings",
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'setup',
    loadChildren: () => import('./pages/setup/setup.module').then( m => m.SetupPageModule)
  },
  {
    path: "dashboard",
    loadChildren: () => import('./pages/terminal-status/terminal-status.module').then(m => m.TerminalStatusPageModule)
  },
  {
    path: 'changelog',
    loadChildren: () => import('./pages/changelog/changelog.module').then( m => m.ChangelogPageModule)
  },
  {
    path: 'qrscanner',
    loadChildren: () => import('./pages/qrscanner/qrscanner.module').then( m => m.QrscannerPageModule)
  },
  {
    path: 'error-report-confirm',
    loadChildren: () => import('./pages/error-report-confirm/error-report-confirm.module').then( m => m.ErrorReportConfirmPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
