import { Injectable } from '@angular/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class StorageManagerService {

  constructor(
    private nativeStorage: NativeStorage,
    private platform: Platform
  ) { }

  public async getItem<T>(key: string, defaultValue: T = null): Promise<T>{
    if(this.platform.is("capacitor")){
      try {
        const item: T = await this.nativeStorage.getItem(key)
        return item != null || item != undefined ? item : defaultValue
      } catch (error) {
        return defaultValue
      }
    }else {
      const item: T =  JSON.parse(localStorage.getItem(key)) as T
      return item == null || item == undefined ? defaultValue : item
    }
    
  }

  public async setItem<T>(key: string, value: T): Promise<void>{
    if(this.platform.is("capacitor")){
      await this.nativeStorage.setItem(key, value)
    }else{
      localStorage.setItem(key, JSON.stringify(value))
    }
  }

  public async delete(key: string){
    if(this.platform.is("capacitor")){
      await this.nativeStorage.remove(key)
    }else{
      localStorage.removeItem(key)
    }
  }

}
