<ion-header>
  <ion-toolbar>
    <ion-title>Statusauswahl</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-list style="background: none" no-lines>
    <ion-item 
    *ngFor="let item of states" 
    class="label" lines="none" 
    (click)="setState(item)">
      {{ item.description }}
    </ion-item>
  </ion-list>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button mode="ios" color="danger" expand="full" (click)="cancel()">
      <ion-icon name="fa-x-mark"></ion-icon>
      Abbrechen  
    </ion-button>
  </ion-toolbar>
</ion-footer>