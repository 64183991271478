import { Injectable, OnInit } from '@angular/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Platform } from '@ionic/angular';
import { FeedbackService, MsgType } from './feedback.service';
import { LocalNotifications, LocalNotificationSchema, PermissionStatus } from "@capacitor/local-notifications"

import * as moment from 'moment'
import { GlobalSettingsService } from './global-settings.service';
export interface NotificationInterval {
  id: number,
  bezeichnung: string,
  interval?: number,
  mobil?: boolean 
}

@Injectable({
  providedIn: 'root'
})
export class NotificationProvider{

  private 

  private itemName = 'Notifications';
  private currentStatus: number;
  private _notifications: NotificationInterval[] = [];

  constructor(
    private storage: NativeStorage, 
    private plt: Platform,
    private settings: GlobalSettingsService
    ) {
    if(!this.plt.is("capacitor")) return 
    this.plt.ready().then(() => {
      this.storage.getItem(this.itemName).then(data => {
        this._notifications = data;
      });      
    });
    
  }
  public async schedule(statusid: number) {
    if(!this.plt.is("capacitor")) return 

      if (statusid === this.currentStatus) return;
        this.currentStatus = undefined;
        LocalNotifications.cancel({
          notifications: this._notifications.map(n => ({id: n.id}))
        })
        const interval = this._notifications.find(value => value.id === statusid);
        
        if (interval && interval.interval) {
          const permission: PermissionStatus = await LocalNotifications.requestPermissions();
          if(permission.display != 'granted') {
            const requestResult: PermissionStatus = await LocalNotifications.requestPermissions();
            if(requestResult.display != 'granted') return
          }

          this.currentStatus = statusid;
          const text = interval.bezeichnung;
          const minText = interval.interval > 1 ? 'Minuten' : 'Minute';

          const message : LocalNotificationSchema = {
            id: statusid,
            title: "AFS-Zeiterfassung",
            body: `Ihr Status ist seit ${interval.interval} ${minText} ${text}`,
            schedule: {
              at: moment().add(interval.interval, "minutes").toDate()
              // at: moment().add(8, "seconds").toDate()
            },
            extra: "extradata",
            actionTypeId: "PAUSE_MESSAGE",
            
          }     

          if(this.settings.crazyMode && statusid == 1) message.sound = "pause.wav"
          else message.sound = "notification.wav" 

          await LocalNotifications.schedule({notifications: [message]})

        }
  } 

  private async save() {
    await this.storage.setItem(this.itemName, this._notifications); 
  }

  get notifications() {
    return this._notifications;
  }

  set notifications(value: NotificationInterval[]) {
    this._notifications = []
    let modified = false;
    if (!value) {
      modified = this._notifications.length > 0;
      this._notifications = [];
    }
    value.forEach(element => {
      if (!this._notifications.find(noti => {
        return noti.id === element.id;
      })) {
        if(!!element.mobil){
          this._notifications.push(element);
          modified = true;
        }
      }
    });
    this._notifications.forEach((element, index) => {
      if (!value.find(noti => {
        return noti.id === element.id;
      })) {
        this._notifications.splice(index, 1);
        modified = true;
      }
    });
    if (modified) {
      this.save();
    }
  }

  async updateItem(item: NotificationInterval) {
    const found = this._notifications.find(value => {
      return value.id === item.id;
    });
    if (found) {
      found.interval = item.interval;
      await this.save();
    }
  }
}
