import { HttpResponse } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { numberToHex } from 'src/utils/colorutils';
import { HttpFunctionResult, HttpService } from './http.service';
import * as moment from 'moment'
import { StorageManagerService } from './storage-manager.service';

export interface State {
  id: number;
  description?: string;
  color?: number;
  mobile?: boolean;
  isWorkingTime: boolean;
  confirmation?: boolean
  startDate?: moment.Moment
  isVacation?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  private _states: State[] = []


  constructor(
    private http: HttpService,
    private storageManager: StorageManagerService
  ) { 
  }

  public get states(): State[] {
    return this._states
  }

  public async getAllMobileStates(): Promise<HttpFunctionResult<State[]>> {

    const result: HttpFunctionResult<State[]> = await this.http.GET({path: "userstate/all", handleError: false})
    if(!result.success) return { success: false }
    this._states = result.data
    this._states.unshift({
      id: 0,
      description: 'Arbeiten',
      mobile: true,
      isWorkingTime: true,
      color: 9159498
    })
    this._states.unshift({
      id: -1,
      description: 'Abwesend',
      mobile: false,
      isWorkingTime: false
    })
    await this.storageManager.setItem<State[]>("STATES", this._states)
    return { success: true, data: this._states }
    
  }

  public async loadStates(): Promise<void> {
    this._states = await this.storageManager.getItem<State[]>("STATES", [])
  }

}
