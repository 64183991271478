import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError, TimeoutError, BehaviorSubject, of } from "rxjs";
import { HttpService } from "../services/http.service";
import { catchError, timeout, switchMap, finalize, take, filter, tap } from "rxjs/operators";
import { FeedbackService } from "../services/feedback.service";
import { StorageManagerService } from "../services/storage-manager.service";
import { AuthService } from "../services/auth.service";
import { OfflineModeService } from "../services/offline-mode.service";
import { NavController } from "@ionic/angular";
import { Router } from "@angular/router";

@Injectable()
export class HttpError implements HttpInterceptor{

    private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null)
    private isRefreshingToken: boolean = false

    constructor(
        private http: HttpService,
        private feedback: FeedbackService,
        private offlineModeSrv: OfflineModeService,
        private storageManager: StorageManagerService,
        private authSrv: AuthService,
        private navCtrl: NavController,
        private router: Router
    ){}
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(req.url == `${this.http.getbaseUrl}/api/auth/login` || (req.url == `${this.http.getbaseUrl}/api/`)) return next.handle(req)
        return next.handle(req).pipe(
            timeout(11000),
            tap(async (response: HttpResponse<any>)=>{
                if(response.status >= 200 && response.status < 300) {
                    this.offlineModeSrv.isOfflineMode = false;
                    await this.offlineModeSrv.fetchOfflineData()
                }
            }),
            catchError((err: any)=>{
                
                if(err instanceof TimeoutError || err.status == 0){
                    if(this.offlineModeSrv.active && this.http.serverVersionGreaterThan("1.1.0") && !this.router.routerState.snapshot.url.includes("terminal")){
                        if(!this.offlineModeSrv.isOfflineMode) this.feedback.showToast("Der Server ist zur Zeit leider nicht erreichbar. Sie befinden sich nun im Offline-Modus.", 3000)
                        this.offlineModeSrv.isOfflineMode = true
                        this.navCtrl.navigateRoot("/tabs/status")
                    }else this.feedback.showError("Der Server ist zur Zeit leider nicht erreichbar. Bitte versuchen Sie es später noch einmal.")
                    
                    return next.handle(err)
                }
                
                
                const httpError: HttpErrorResponse = (err as HttpErrorResponse)
                if(httpError.status == 401) return this.handleUnautorized(req, next)
                if(httpError.status == 403) {
                    this.feedback.showError(httpError?.error?.message, 4000)
                    this.authSrv.signOut()
                    return next.handle(req)
                }
                console.error(err)

                this.feedback.showError(httpError?.error?.message || "Es ist ein Unbekannter Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.")
                return throwError(err)

            }) 
        )
    }

    private handleUnautorized(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if(!this.isRefreshingToken){ 
            this.tokenSubject.next(null)
            this.isRefreshingToken = true
            this.http.token = ""
            return this.http.getNewAccessToken().pipe(
                timeout(20000),
                switchMap( (result: HttpResponse<{access_token: string, refresh_token: string}>)=>{
                    if(!result) return of(null)
                    this.http.token = result.body.access_token
                    this.http.refreshToken = result.body.refresh_token
                    this.storageManager.setItem<string>('ACCESS_TOKEN', this.http.token)
                    this.storageManager.setItem<string>('REFRESH_TOKEN', this.http.refreshToken)
                    this.tokenSubject.next(this.http.token)
                    return next.handle(this.addToken(req)) 
                }),
                finalize(()=> this.isRefreshingToken = false)
            )
        }else{
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(req))
                })
            )
        }
    }

    private addToken(req: HttpRequest<any>){
        if(!this.http.token) return req
        return req.clone({
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.http.token}`,
                "x-version": this.http.serverVersion
            })
        })
    }


    
}