import { Injectable } from '@angular/core';
import { LoadingController, AlertController,  Platform, ModalController, NavController, ToastController} from '@ionic/angular';
import { ComponentProps, ComponentRef } from '@ionic/core';
import { TapticEngine } from '@awesome-cordova-plugins/taptic-engine/ngx';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { HttpFunctionResult } from './http.service';
import { BehaviorSubject, Subscription } from 'rxjs';

export enum MsgType {
  Info,
  Error
}

export enum VibrateType {
  light = "light",
  medium = "medium",
  heavy = "heavy"
}


@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  private _loading = null;
  private loadingVisible: Boolean = false;
  private currentToast;

  public statusLoading: {isVisable: boolean, text: string, outAnimation?: boolean} = {isVisable: false, text: "Laden...", outAnimation: false}


  get loading() {
    if (!this._loading) {
      this._loading = this.loadingCtrl.create({
        
      });
    }
    return this._loading;
  }

  private load 

  public async hideStatusLoading(){
    return new Promise(resolve=>{
      this.statusLoading.outAnimation = true
      setTimeout(() => {
        this.statusLoading.isVisable = false
        this.statusLoading.outAnimation = false
        return resolve(null)
      }, 300);
    })
  }

  public showStatusLoading(text: string = "Laden..."){
    this.statusLoading.text = text
    this.statusLoading.isVisable = true 
  }

  async updateLoading(newContent: string) {
    this.load = await this.loadingCtrl.create({
      message: newContent,
      cssClass: "customMessage",
    })
    if (!this.loadingVisible) {
      this.loadingVisible = true;
      return await this.load.present();
    }
  }

  async hideLoading() {
    if (this.loadingVisible) {
      this.loadingVisible = false;
      await this.load?.dismiss();
      await this.load?.dismiss();
      this.load = null;
    }
     
    }

  async showMsg(Type: MsgType, Msg: string) {
    const prom = new Promise<boolean>(async resolve => {
      let title: string;
      switch (Type) {
        case MsgType.Info:
          title = "Information";
          break;
        case MsgType.Error:
          title = "Fehler";
          break;
      }
      const alert = await this.alertCtrl.create({
        header: title,
        message: Msg,
        backdropDismiss: false,
        cssClass: "customMessage",
        buttons: [
          {
            text: "OK",
            handler: () => {
              resolve(true);
            }
          }
        ]
      });
      await alert.present();
    });
    return await prom;
  }

  async confirm(Msg: string, yesText: string = "Ja", noText: string = "Nein"): Promise<boolean> {
    const prom = new Promise<boolean>(async resolve => {
      const alert = await this.alertCtrl.create({
        header: "Bestätigen",
        message: Msg,
        cssClass: "customMessage",
        backdropDismiss: false,
        buttons: [
          {
            text: yesText,
            handler: () => {
              resolve(true);
            }
          },
          {
            text: noText,
            handler: () => {
              resolve(false);
            }
          }
        ]
      });
     await alert.present();
    });
    await this.hideLoading();
    return await prom;
  }

  async showPromp(title: string, message: string, placeholder){
    return new Promise(async resolve=>{
      const alert = await this.alertCtrl.create({
        header: title,
        message: message,
        cssClass: "customMessage",
        inputs: [
          {
            name: title,
            placeholder: placeholder
          }
        ],
        buttons:[
          {
            text: "Abbrechen",
            handler: ()=>{
              resolve(false)
            }  
          },
          {
            text: "OK",
            handler: data =>{
              resolve(data[title]) ;
            }
          }
        ]
      });
      await alert.present()
    })
  }

  async showToast(Msg: string, duration?: number, position?: string) {
    const dur = duration ? duration : 3000;
    const posi = position ? position : 'bottom';
    if (this.currentToast) {
      await this.currentToast.dismiss();
    }
    const toast = await this.toastCtrl.create({
      message: Msg,
      duration: dur,
      cssClass: "customMessage",
      position: 'top'
    });
    this.currentToast = toast;
    toast.onDidDismiss().then(()=>{
      this.currentToast = undefined;
    })
    await toast.present();
  }

  public async vibrate(iosStyle:  VibrateType = VibrateType.medium, androidDuration: number = 15){
    if(!this.platform.is("capacitor")) return
    if(this.platform.is("ios")) await this.tapticEngine.impact({style: iosStyle})
    else  this.vibration.vibrate(androidDuration)
  } 

  private errToastShow: boolean = false
  public async showError(message: string, duration: number = 3000){
    if(this.errToastShow) return
    this.errToastShow = true
    const toast = await this.toastCtrl.create({
      header: "Fehler",
      message: message,
      color: "danger",
      duration: duration,
      position: "top",
      keyboardClose: false,
      icon: "fa-exclamation",
      mode: "ios",
      buttons: [{
        side: "end",
        icon: "fa-x-mark",
        role: "cancel"
      }]
    })
    toast.removeAttribute('tabindex'); 
    toast.onDidDismiss().then(()=>this.errToastShow = false)
    await toast.present()
}

public async openModal<T>(
  component: ComponentRef, 
  componentProps?: ComponentProps<ComponentRef>,
  resolve?: (result: HttpFunctionResult<T>)=>void,
  backdropDismiss: boolean = true,
  mode: "md" | "ios" = undefined,
  breakpoints: number[] = undefined
  ){
  const modal = await this.modalCtrl.create({
    component: component,
    componentProps: componentProps,
    keyboardClose: true,
    mode: mode,
    backdropDismiss: backdropDismiss,
    initialBreakpoint: breakpoints ? breakpoints[0] : undefined,
    breakpoints: breakpoints
  })
  if( modal?.componentProps) modal.componentProps["modalRef"] = modal
  
  modal.onWillDismiss().then(async (result: any)=>{
    if(!(result.data as T)) {
      if(resolve) {
        resolve({success: false})
        return
      } 
    }
    else{
      resolve({
        success: true,
        data: result.data as T
      })
      return
    }
  })

  this.vibrate(VibrateType.medium)
  await modal.present()

}

  public qrCodeResult: BehaviorSubject<HttpFunctionResult<string>> = new BehaviorSubject<HttpFunctionResult<string>>(null)

  public async getQRCodeData(): Promise<HttpFunctionResult<string>>{
    return new Promise(async resolve=>{
      await this.navCtrl.navigateForward("qrscanner", {animated: false})
      const sub: Subscription = this.qrCodeResult.asObservable().subscribe((data: HttpFunctionResult<string>)=>{
        if(data == null) return
        sub.unsubscribe()
        this.qrCodeResult = new BehaviorSubject<HttpFunctionResult<string>>(null)
        resolve({success: true, data: data.data})
      })
    })
  }

  public async getInputText<T>(message: string, header: string, autofocus: boolean = false, placeholder: string = "", type: "text" | "number" | "password" = "text"): Promise<HttpFunctionResult<T>>{
    return new Promise<HttpFunctionResult<T>>(async resolve=>{
      const alert = await this.alertCtrl.create({
        header: header,
        message: message,
        cssClass: "customMessage",
        backdropDismiss: false,
        inputs: [ { type: type, placeholder: placeholder }],
        buttons: [
          {text: "Ok",handler: (value: any)=>resolve({success: true, data: value[0]})},
          {text: "Abbrechen", handler: ()=>resolve({success: false})}
        ]
      })
      await alert.present()

      const input: any = document.querySelector('ion-alert input');
      if(autofocus) input.focus()
      
      // Wenn enter gedrückt wird
      input.addEventListener('keydown', (event) => {
        if (event.key === 'Enter'){
          resolve({success: true, data: input.value})
          alert.dismiss()
        }
      });

    })
  }

 

  constructor(
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private tapticEngine: TapticEngine,
    private platform: Platform,
    private vibration: Vibration,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private navCtrl: NavController
  ) {
    this.vibrate(VibrateType.medium)
  }
}
