import { registerPlugin } from '@capacitor/core';

export interface ConnectionData{
    jwt: string, 
    baseURL: string 
}
export interface WatchConnect {
    saveJWT(options: ConnectionData): Promise<{result: ConnectionData}>;
}
const WatchConnect = registerPlugin<WatchConnect>('Watch');

export default WatchConnect;
 