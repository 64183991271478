import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController, Platform } from '@ionic/angular';
import { AuthService, LoginCredentials } from 'src/app/services/auth.service';
import { DarkmodeService } from 'src/app/services/darkmode.service';
import { FeedbackService } from 'src/app/services/feedback.service';
import { GlobalSettingsService } from 'src/app/services/global-settings.service';
import { HttpFunctionResult, HttpService } from 'src/app/services/http.service';
import { OfflineModeService } from 'src/app/services/offline-mode.service';
import { StorageManagerService } from 'src/app/services/storage-manager.service';
import { ZeroconfService } from 'src/app/services/zeroconf.service';
import { environment } from 'src/environments/environment';
import { Swiper } from 'swiper';
import { ErrorReportConfirmPage } from '../error-report-confirm/error-report-confirm.page';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.page.html',
  styleUrls: ['./setup.page.scss'],
})
export class SetupPage implements OnInit  {

  public serverAddress: string = ""
  public loginCredentials: LoginCredentials = {
     username:  "",
     password:  ""    
  }

  public appSettings: {errorReport: boolean, offlineMode: boolean, darkmode: boolean} = {
    errorReport: true,
    offlineMode: true,
    darkmode: true
  }

  public isDemo: boolean = environment.isDemo

  // @ViewChild(IonSlides, {static: false}) slides: IonSlides
  @ViewChild('swiper') swiperRef: any;
  @ViewChild("usernameInput", {static: false}) usernameInput: IonInput;
  swiper?: Swiper;

  public currentSlide: number = 1

  public allowSwipe: boolean = false

  public slideOpts = {  
    initialSlide: 0,
    locked: true,
    speed: 600
  };

  constructor(
    private feedback: FeedbackService,
    private http: HttpService,
    private storageManager: StorageManagerService,
    private authSrv: AuthService, 
    public platform: Platform, 
    private settings: GlobalSettingsService,
    private changeDetector: ChangeDetectorRef,
    private darkmode: DarkmodeService,
    private modalCtrl: ModalController, 
    private offlineModeSrv: OfflineModeService,
    public zeroconfSrv: ZeroconfService
  ) {
    this.platform.ready().then(async ()=>{
      this.serverAddress = (await this.storageManager.getItem<string>("BASEURL", ""))
        .replace("http://", "")
        .replace("https://", "")
      if(!this.platform.is("cordova") && this.serverAddress == "") this.serverAddress = window.location.origin 
    }) 
    this.appSettings.darkmode = this.darkmode.isDarkModeValue
  } 
  ngOnInit(): void {
    this.zeroconfSrv.subscribeZeroConf()
  } 

  public async focusNextInput(nextInput: IonInput) {
    await nextInput.setFocus();
    this.changeDetector.detectChanges();
  } 

   

  private connectTry: number = 1
  private connectUrl: string = ""
  public async continue(){
    if(this.currentSlide == 1) await this.saveServer()
    else if(this.currentSlide == 2) await this.login()
    else await this.complete()
  }

  private async saveServer(){
      //http wegschneiden damit zuerst mit https getestet werden kann
      if(this.serverAddress.startsWith("http://")) this.serverAddress = this.serverAddress.replace("http://", "")
      if(this.serverAddress.startsWith("https://")) this.serverAddress = this.serverAddress.replace("https://", "")
      if(this.connectTry == 1){ 
        await this.feedback.updateLoading("Teste Verbindung...")
        this.connectUrl = `https://${this.serverAddress}`
        this.http.defaultURL = `https://${this.serverAddress}`
      }else if(this.connectTry == 2){
        this.connectUrl = `http://${this.serverAddress}`
        this.http.defaultURL = this.connectUrl
      }else {
        await this.feedback.hideLoading()
        await this.feedback.showError("Keine Verbindung möglich. Bitte versuchen sie eine andere Server-Adresse.")
        this.connectTry = 1
        return 
      }

      const result: HttpFunctionResult<any> = await this.http.GET({
        path: "",
        timeout: 5000,
        handleError: false
      })

      if(result.success){
        await this.storageManager.setItem<string>("BASEURL", this.connectUrl)
        await this.feedback.hideLoading()
        this.connectTry = 1
        if(this.connectUrl.startsWith("http://")) {
          if(!(await this.feedback.confirm('Die Verbindung zu diesem Server ist unsicher, da sie nicht über "https" erreichbar ist. Möchten Sie trotzdem fortfahren?'))){
            await this.storageManager.delete("BASEURL")
            return
          }
        }
        this.zeroconfSrv.unsubscribeZeroConf()
        await this.next()
        setTimeout(async () => await this.usernameInput.setFocus(), 500); 
      }else{
        this.connectTry++
        this.continue()
      }
  }

  public async login(){
    await this.feedback.updateLoading("Anmelden...")
    const result: HttpFunctionResult<any> = await this.authSrv.login(this.loginCredentials)
    await this.feedback.hideLoading()
    if(this.isDemo) await this.storageManager.setItem<string>("BASEURL", this.connectUrl)
    if(result.success) await this.next()
  }

  public async complete(){

    //Offline-Mode
    await this.storageManager.setItem<boolean>("OFFLINE_MODE_ACTIVE", this.appSettings.offlineMode)
    this.offlineModeSrv.active = this.appSettings.offlineMode

    //Sentry
    await this.settings.setEntryEnabled(this.appSettings.errorReport)

    await this.modalCtrl.dismiss()
  }

  public async next(){ 
    if(this.isDemo) return
    this.allowSwipe = true
    this.changeDetector.detectChanges()
    this.swiperRef?.nativeElement?.swiper.slideNext()
    this.allowSwipe = false
    this.currentSlide++
  }

  public async serverKeyDown(event: KeyboardEvent){
    if(event.key == "Tab") event.preventDefault()
    if(event.key == "Enter") await this.saveServer()
  }

  public async startDemo(){
    this.connectUrl = environment.testserver
    this.http.defaultURL = environment.testserver
    await this.login() 

  }

  public async serverClicked(server: {name?: string, domain?: string }){
    this.serverAddress = server.domain
    await this.saveServer()
  }  

  public async scanQRCode(){
    const qrResult: HttpFunctionResult<string> = await this.feedback.getQRCodeData()
    if(!qrResult.data) return
    this.serverAddress = qrResult.data 
    await this.saveServer() 
  }

  public async showErrorReportText(){
    await this.feedback.openModal(ErrorReportConfirmPage)
  }

  public async toggleDarkmode(){
    this.settings.saveDarkmode(this.appSettings.darkmode)
  }

}
