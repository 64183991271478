import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FeedbackService, MsgType } from 'src/app/services/feedback.service';
import { GlobalSettingsService } from 'src/app/services/global-settings.service';
import { HttpFunctionResult } from 'src/app/services/http.service';
import { State } from 'src/app/services/status.service';
import { StempelService } from 'src/app/services/stempel.service';
import { UserWithState } from 'src/app/services/terminal.service';
import { HttpService } from 'src/app/services/http.service';
import { AuswahlPage } from '../auswahl/auswahl.page';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import * as moment from 'moment';

@Component({
  selector: 'app-stempel',
  templateUrl: './stempel.page.html',
  styleUrls: ['./stempel.page.scss'],
})
export class StempelPage {

  btnTitle: string = 'Kommen';
  pauseTitle: string = 'Pause';
  isPaused: boolean = false;
  isAbsent: boolean = true;
  isWorking: boolean = false;
  public loaded: boolean = false

  public fontSize: number = null

  public stateTimer: any = null

  constructor(
    private feedback: FeedbackService,
    public http: HttpService,
    public stempSrv: StempelService,
    private modalCrtl: ModalController,
    private settings: GlobalSettingsService) {
      this.checkUser()
  }

  async ngOnInit(){
    this.fontSize = await this.settings.getStatus()
    this.initStateTimer()
  }

  private initStateTimer(){
    if(!this.settings.autoStamp) return
    // if(!this.stempSrv.fromNFC || !this.http.serverVersionGreaterThan('1.1.0'))
    this.stateTimer = setTimeout(async () => {
      if(this.isWorking) await this.setState(-1)
      else await this.setState(0)
    }, 30000);
  }

  ngOnDestroy(){
    this.stempSrv.fromNFC = false
    clearTimeout(this.stateTimer)
  }

  private checkUser() {
    this.isAbsent = this.stempSrv.currentUser.closed == 1 || !this.stempSrv.activeState || !(this.stempSrv.currentUser.state === 0 || this.stempSrv.activeState.mobile);
    this.isPaused = !this.isAbsent && this.stempSrv.currentUser.state === 1;
    this.isWorking = !this.isAbsent && this.stempSrv.currentUser.state === 0;
    if (this.isWorking) this.btnTitle = 'Gehen';
    else if (!this.isAbsent) this.btnTitle = 'Arbeiten';
    else this.btnTitle = 'Kommen';
  };


  public async setState(state: number) {
    await this.feedback.updateLoading("Status setzen...")
    const result: HttpFunctionResult<any> = await this.stempSrv.setNewUserState({userId: this.stempSrv.currentUser.userId, state: state})
    if(result.success) await this.cancel(state)
    await this.feedback.hideLoading()
  }


  async choose() {
    clearTimeout(this.stateTimer)
    await this.feedback.openModal<State>(AuswahlPage, {}, async (result: HttpFunctionResult<State>)=>{
      if(!result.success) return
      await this.setState(result.data.id) 
    }, true, "md")
  }

  async cancel(state: number = null) {
    if(state != null){
      var newUser: UserWithState = Object.assign({}, this.stempSrv.currentUser)
      newUser.state = state
      newUser.closed = state == -1 ? 1 : 0
      await this.modalCrtl.dismiss(newUser)
    } else await this.modalCrtl.dismiss()
  }

  public async showWorkTime(){
    await this.feedback.updateLoading("Arbeitszeit wird berechnet...")
    const result: HttpFunctionResult<{workingtime: number}> = await this.http.GET({path: `user/worktime/${this.stempSrv.currentUser.userId}`})
    await this.feedback.hideLoading()
    if(result.success) await this.feedback.showMsg(MsgType.Info, `Heutige Arbeitszeit: ${moment.utc(result.data?.workingtime * 1000 ).format("HH:mm")} Std.`)
  }


}
