<ion-header [translucent]="true">
  <ion-toolbar style="--background: transparent">
    <ion-buttons slot="start">
      <ion-back-button text="" (click)="back()"></ion-back-button>
    </ion-buttons>
    <ion-title>Fehler-Berichte</ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <ion-item class="toogle-item">
      <ion-label>
        <div>Fehlerberichte senden</div>
        <p class="ion-item-p">Tragen Sie dazu bei die Qualität unserer Dienstleistungen zu verbessern.</p>
      </ion-label>
      <ion-toggle [(ngModel)]="sentryEnabled" slot="end" mode="ios" (ngModelChange)="changeSentry()"></ion-toggle>
    </ion-item>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <h1>Fehlerberichterstattung aktivieren?</h1>
  <p>Wir arbeiten kontinuierlich daran, unsere App zu verbessern und Ihre Erfahrungen zu optimieren. <br> Um dabei effektiv auf auftretende Fehler reagieren zu können, bieten wir Ihnen die Möglichkeit, uns bei der Fehleridentifikation zu unterstützen. <br> Falls Sie auf Unregelmäßigkeiten oder Probleme stoßen, freuen wir uns über Ihre Mithilfe bei der Fehlerberichterstattung.</p>

  <p>Die Zustimmung zur Fehlerberichterstattung ist optional, und die App kann auch ohne Zustimmung verwendet werden. Dennoch würde uns Ihre Unterstützung durch die Aktivierung dieser Funktion sehr bei der kontinuierlichen Verbesserung und Entwicklung der App helfen.</p>
  
  <h2>Welche Daten werden an uns gesendet?</h2>
  <p>Um die Fehlerursache zu identifizieren und zu beheben, werden folgende anonymisierte Daten an uns gesendet:</p>

  <ul>
    <li>Geräteinformationen (z.B. Gerätetyp, Betriebssystem)</li>
    <li>App-Version</li>
    <li>App-Status (z.B. Absturz, Hängenbleiben, Code-Fehler)</li>
    <li>Fehlerprotokolle und -berichte</li>
  </ul>

  <p>Ihre Privatsphäre ist uns wichtig, und wir versichern Ihnen, dass sämtliche Daten anonymisiert von uns behandelt werden.</p>
  <p>Außerdem ist uns wichtig zu betonen, dass zu keiner Zeit ein Zugriff oder Einsicht auf Server-Informationen oder Ihre Datenbank besteht!</p>

  <p>Vielen Dank für Ihre Mitarbeit und Ihr Verständnis. Zusammen gestalten wir eine noch bessere App-Erfahrung!</p>

  <p>Ihr AFS-Software-Team</p>

</ion-content>

<!-- <ion-footer>
  <ion-toolbar>
    <div style="display: flex; justify-content: space-between; column-gap: 5px;">
      <ion-button mode="ios" fill="outline" style="width: 50%; --border-color: var(--red)" color="danger">Ablehnen</ion-button>
      <ion-button mode="ios" style="width: 50%; --background: var(--green)">Zustimmen</ion-button>
    </div>
  </ion-toolbar>
</ion-footer> -->