import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
// import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { Ndef, NFC } from '@ionic-native/nfc/ngx';
import { ThemeDetection } from '@ionic-native/theme-detection/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { TapticEngine } from '@awesome-cordova-plugins/taptic-engine/ngx';
import { HttpError } from './interceptors/httperror.interceptor';
import { Zeroconf } from '@awesome-cordova-plugins/zeroconf/ngx';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular";

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })],
    providers: [
        SplashScreen,
        NFC,
        Ndef,
        Vibration,
        TapticEngine,
        ThemeDetection,
        Zeroconf,
        NativeStorage,
        AndroidPermissions,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: HttpError, multi: true },
        { provide: ErrorHandler, useValue: Sentry.createErrorHandler({showDialog: false})},
        { provide: Sentry.TraceService, deps: [Router] },
        { provide: APP_INITIALIZER, useFactory: ()=> () => {}, deps: [Sentry.TraceService], multi: true }
    ],
    bootstrap: [AppComponent], 
})
export class AppModule {}
