import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { StempelPage } from '../pages/stempel/stempel.page';
import { FeedbackService } from './feedback.service';
import { HttpFunctionResult, HttpService, ServerInformations } from './http.service';
import { OfflineModeService } from './offline-mode.service';
import { State, StatusService } from './status.service';
import { UserWithState } from './terminal.service';

@Injectable({
  providedIn: 'root'
})
export class StempelService {

  private _currentUser: UserWithState
  private _activeState: State
  public fromNFC: boolean = false

  constructor(
    private feedback: FeedbackService,
    private http: HttpService,
    private offlineModeSrv: OfflineModeService,
    private stateSrv: StatusService
  ) { 
    
  }

  public get currentUser(): UserWithState{
    return this._currentUser
  }

  public set currentUser(value: UserWithState){
    this._currentUser = value
  }

  public get activeState(): State{
    return this._activeState
  }

  public async openStampPage(user: UserWithState, callback: (newUser: HttpFunctionResult<UserWithState>)=>void){
    this.feedback.updateLoading("Status holen...")
    const stateResult: HttpFunctionResult<State> = await this.getCurrentStateFromAPI(user)
    if(!stateResult.success){ 
      await this.feedback.hideLoading()
      callback({success: false})
      return
    }
    this._activeState = stateResult.data
    user.state = this._activeState.id
    this._currentUser = user
    await this.feedback.openModal<UserWithState>(StempelPage, {}, (result: HttpFunctionResult<UserWithState>)=>callback(result), false)
    await this.feedback.hideLoading()
  }


  public async getCurrentStateFromAPI(user: UserWithState): Promise<HttpFunctionResult<State>>{
    const result: HttpFunctionResult<{state: number}> = await this.http.GET({path: `userstate/terminal/currentstate/${user.userId}`})
    if(!result.success) return {success: false}
    const state: State = this.stateSrv.states.find(state => state.id == result.data.state) 
    if(!state) return {success: false}
    return {success: true, data: state}
  }

  public async setNewUserState(data:{userId: number, state: number}): Promise<HttpFunctionResult<any>>{
    if(!this.offlineModeSrv.isOfflineMode || !this.http.serverVersionGreaterThan("1.1.0")){

      const result: HttpFunctionResult<any> = await this.http.PUT({
        path: "userstate/terminal/currentstate",
        body: data
      })

      if(result.success) return {success: true}
      else{
        if(!this.offlineModeSrv.active || !this.http.serverVersionGreaterThan("1.1.0")) return {success: false}
        await this.offlineModeSrv.addOfflineStateData({...data, startTime: moment.utc().toDate()})
        return {success: true}
      }

    }else{
      await this.offlineModeSrv.addOfflineStateData({...data, startTime: moment.utc().toDate()})

      //Schauen ob server wieder erreichbar ist und dann die daten hochladen
      const result: HttpFunctionResult<ServerInformations> = await this.http.GET({path: "", handleError: false})
      if(result.success){
        this.offlineModeSrv.isOfflineMode = false
        await this.offlineModeSrv.fetchOfflineData()
        await this.feedback.showToast("Zeiterfassung ist wieder Online", 1500)
      }
      return {success: true}
    } 
  } 

}
