import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  private _globalTime: BehaviorSubject<number> = new BehaviorSubject<number>(1)

  constructor(
    private ngZone: NgZone
  ) { }

  public get globalTime(): Observable<number>{
    return this._globalTime.asObservable()
  }

  public startTimer(){
    setInterval(()=> this.ngZone.run(()=>this._globalTime.next(1)) , 1000)
  }


}
 