import { Injectable } from '@angular/core';
import WatchConnect from '../native/watchconnect';
import { FeedbackService, MsgType } from './feedback.service';
import { HttpService } from "./http.service"
import WidgetConnect from '../native/widgetconnect';
import { HttpResponse } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { StorageManagerService } from './storage-manager.service';

@Injectable({
  providedIn: 'root'
})
export class NativeService {

  constructor(
    private httpSrv: HttpService,
    private platform: Platform,
    private storageManager: StorageManagerService,
    private feedback: FeedbackService
  ) { }
 
  public async connectToWatch(){
    const result = await WatchConnect.saveJWT({jwt: this.httpSrv.refreshToken, baseURL: this.httpSrv.getbaseUrl}) 
    if(result.result)await this.feedback.showMsg(MsgType.Info, "Die Watch-App wurde erfolgreich eingerichtet!")
    else await this.feedback.showMsg(MsgType.Error, "Bitte stellen Sie sicher, dass die App auf Ihrer Apple Watch geöffnet ist und das Always-On-Display nicht aktiv ist.")
  }

  public async connectWidget(isRefresh: boolean = false){
    if(!this.platform.is("capacitor")) return
    if(!this.httpSrv.getbaseUrl || !this.platform.is("ios")) return
    if(isRefresh) if(await this.storageManager.getItem<boolean>("HAS_WIDGET_TOKEN")) return
    this.httpSrv.getExtentionToken().subscribe(async (result: HttpResponse<{access_token: string}>)=>{
      const nativeResult: {result: boolean} = await WidgetConnect.saveWidget({jwt: result.body.access_token, baseURL: this.httpSrv.getbaseUrl})
      if(!nativeResult.result) return
      await this.storageManager.setItem<boolean>("HAS_WIDGET_TOKEN", true)
    })
  }
}