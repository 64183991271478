<ion-content>
  
  <section *ngIf="isDemo">
    <ion-button *ngIf="isDemo" expand="block" (click)="startDemo()" mode="ios">
      Demo starten
      <ion-icon name="fa-arrow-right" style="margin-left: 10px;"></ion-icon>
    </ion-button>
  </section>
  <section *ngIf="!isDemo">
        <swiper-container #swiper [speed]="500" [loop]="false" [allowSlideNext]="allowSwipe" [allowSlidePrev]="false">

          <swiper-slide>
            <div style="width: 100%;" [class.animateOut]="currentSlide == 2">
              <img id="logo" src="../../../assets/icon/favicon.png" alt="">
              <h1>Einrichtung</h1>
              <p>Bitte geben Sie die URL zu Ihrem AFS-Zeiterfassungs-Server ein und drücken Sie auf weiter.</p>
              <div style="display: flex; align-items: center; column-gap: 5px;">
                <ion-input type="url" placeholder="Server" [(ngModel)]="serverAddress" (keydown)="serverKeyDown($event)" mode="ios"></ion-input>
                <ion-button (click)="scanQRCode()" *ngIf="platform.is('capacitor')">
                  <ion-icon name="fa-qrcode"></ion-icon>
                </ion-button>
              </div>
    
              <h2 style="font-size: 16px; margin-top: 30px;" *ngIf="zeroconfSrv.zeroconfSever.length > 0">Gefundene Server:</h2>
              <ion-item class="zeroconfServer" *ngFor="let server of zeroconfSrv.zeroconfSever" (click)="serverClicked(server)">
                <ion-icon name="fa-server" slot="start" style="color: var(--text);">
                </ion-icon>
                <ion-label>
                  <div>{{server.name}}</div>
                  <div>{{server.domain}}</div>
                </ion-label> 
              </ion-item>
            </div> 
          </swiper-slide> 
 
          <swiper-slide>
            <div style="width: 100%;" [class.animateIn]="currentSlide == 2" [class.animateOut]="currentSlide == 3">
                <h1>Anmelden</h1> 
                <ion-label class="label" position="stacked">Anmeldename</ion-label>
                <ion-input #usernameInput type="text" placeholder="Max" [(ngModel)]="loginCredentials.username" mode="ios"></ion-input>
                <ion-label class="label" position="stacked">Passwort</ion-label>
                <ion-input type="password" placeholder="*********" [(ngModel)]="loginCredentials.password" (keyup.enter)="login()" mode="ios"></ion-input>
            </div>
          </swiper-slide>

          <swiper-slide>
            <h1>Einstellungen</h1> 
            <div style="width: 100%;" [class.animateIn]="currentSlide == 3">
              
              <ion-item class="toogle-item">
                <ion-label>
                  <div>Fehlerberichte senden</div>
                  <p class="ion-item-p">Tragen Sie dazu bei die Qualität unserer Dienstleistungen zu verbessern. <br> <a (click)="showErrorReportText()">Mehr erfahren...</a></p>
                </ion-label>
                <ion-toggle [(ngModel)]="appSettings.errorReport" slot="end" mode="ios"></ion-toggle>
              </ion-item>
              <ion-item class="toogle-item">
                <ion-label>
                  <div>Automatischer Offline-Modus</div>
                  <p class="ion-item-p">Bei Verbindungsproblemen zur Server, wird der Status vorübergehend lokal gespeichert und später übertragen (nicht im Terminal).</p>
                </ion-label>
                <ion-toggle [(ngModel)]="appSettings.offlineMode" slot="end" mode="ios"></ion-toggle>
              </ion-item>
              <ion-item class="toogle-item">
                <ion-label>
                  <div>Darkmode</div>
                  <p class="ion-item-p">Seiten-Inhalte werden dunkel dargestellt.</p>
                </ion-label>
                <ion-toggle [(ngModel)]="appSettings.darkmode" slot="end" (ngModelChange)="toggleDarkmode()" mode="ios"></ion-toggle>
              </ion-item>

            </div>
          </swiper-slide>

        </swiper-container> 
  </section>
</ion-content>

<ion-footer *ngIf="!isDemo" class="ion-no-border">
  <ion-toolbar>
    <ion-button expand="block" color="primary" (click)="continue()" style="margin-top: 20px;" mode="ios">
      {{currentSlide == 1 ? 'Weiter' : currentSlide == 2 ? 'Anmelden' : 'Abschließen'}}
      <ion-icon *ngIf="currentSlide != 3" name="fa-arrow-right" style="margin-left: 10px;"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-footer>
