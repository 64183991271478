import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeedbackService } from './feedback.service';
import { HttpFunctionResult, HttpService, ServerInformations } from './http.service';
import { NativeService } from './native.service';
import { StorageManagerService } from './storage-manager.service';
import { UsersService } from './users.service';

export interface LoginCredentials{
  username: string,
  password: string
}

export enum Role{
  USER = "user",
  TERMINAL = "terminal",
  REFRESH = "refresh"
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpService,
    private storageManager: StorageManagerService,
    private feedback: FeedbackService,
    private nativeSrv: NativeService,
    private userSrv: UsersService
  ) { } 

  public async login(credentials: LoginCredentials): Promise<HttpFunctionResult<any>>{

    return new Promise(async resolve=>{
      await this.http.POST({
        path: "auth/login",
        body: credentials,
        handleError: false,
        withAuth: false,
        statusResult: async (status: number, result: HttpFunctionResult<{access_token: string, refresh_token: string}>, err: HttpErrorResponse)=>{
          if(result.success){
            const token: string = result.data.access_token
            const refreshToken: string = result.data.refresh_token
            this.http.token = token
            this.http.refreshToken = refreshToken
            await this.storageManager.setItem<string>("ACCESS_TOKEN", token)
            await this.storageManager.setItem<string>("REFRESH_TOKEN", refreshToken)
            await this.nativeSrv.connectWidget()
    
            //Server informationen holen
            const infoResult: HttpFunctionResult<ServerInformations> = await this.http.GET({path: ""})
            if(infoResult.success) this.http.serverInformations = infoResult.data

            resolve({success: true})
          }else{
            if(status == 401 || status == 404 || status == 403){
              await this.feedback.showError(err?.error?.message || "Falsche Benutzerdaten")
              resolve({success: false})
            }else{
              console.error(err);
              await this.feedback.showError("Keine Verbindung mit dem Server möglich. Bitte überprüfen Sie Ihre Einstellungen.")
              resolve({success: false})
            }
          }
        }
      })
    })
  }
 
  public async signOut(){
    await this.storageManager.delete("ACCESS_TOKEN")
    await this.storageManager.delete("REFRESH_TOKEN")
    await this.userSrv.openSetupAndLoadData()
  }



}
