import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpFunctionResult, HttpService } from './http.service';
import { StorageManagerService } from './storage-manager.service';
import * as Crypto from 'crypto-js'

export interface UserWithState{
  userId?: number,
  firstName?: string,
  lastName?: string,
  closed?: boolean | number,
  state?: number,
  image?: string
}

@Injectable({
  providedIn: 'root'
})
export class TerminalService {

  private _userWithStates: UserWithState[] = []
  private _dashboardFromSettings: boolean = false

  constructor(
    private http: HttpService,
    private storageSrv: StorageManagerService
  ) { }

  public get userWithStates(): UserWithState[]{
    return this._userWithStates
  }

  public set userWithStates(values: UserWithState[]){
    this._userWithStates = values
  }

  public set dashboardFromSettings(value: boolean){
    this._dashboardFromSettings = value
  }

  public get dashboardFromSettings(): boolean{
    return this._dashboardFromSettings
  }

  public async loadTerminalData(withImages: boolean = true): Promise<HttpFunctionResult<any>>{

    if(this.http.serverVersionGreaterThan("1.2.0"))
      this.http.GET({path: "terminal-exit-password"}).then(async (response: HttpFunctionResult<{terminal_exit_password: string}>)=>{
        if(response.success)
        await this.storageSrv.setItem("TERMINAL_EXIT_PASSWORD", !response.data.terminal_exit_password ? null : Crypto.AES.encrypt(response.data.terminal_exit_password, "$vhM1CU&Ggdd2m9dve@GiYnSHk").toString())
      })

    const response: HttpFunctionResult<UserWithState[]> = await this.http.GET({path: `userstate/terminal/all?withImage=${withImages}`})
    if(!response.success) return {success: false}
    if(!withImages) this._userWithStates = response.data.map(b => ({
      ...b,
      image: this._userWithStates.find(uws => uws.userId == b.userId).image,
    }))
    
    else this._userWithStates = response.data
    return {success: true}

  }

  public replaceUserWithNewState(userId: number, newState: UserWithState){
    this.userWithStates.splice(this.userWithStates.findIndex(us => us.userId == userId), 1)
    this.userWithStates.push(newState)
    this.userWithStates = [...this.userWithStates]
  }



}
