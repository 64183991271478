import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { State, StatusService } from 'src/app/services/status.service';

@Component({
  selector: 'app-auswahl',
  templateUrl: './auswahl.page.html',
  styleUrls: ['./auswahl.page.scss'],
})
export class AuswahlPage {

  constructor(
    public stateSrv: StatusService,
    private modalCtrl: ModalController) {}

  public get states() {
    return this.stateSrv.states.filter((status) => status.id > 1 && !!status.mobile)
    .sort((s1: State, s2: State) => {
      return s1.description.toLowerCase().localeCompare(s2.description.toLowerCase());
    })
  }

  public async cancel(data: State = null) {
    this.modalCtrl.dismiss(data)
  } 

  async setState(item: State) {
    this.cancel(item)
  }
}
