import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
// import { BackgroundFetch } from '@transistorsoft/capacitor-background-fetch';
import { FeedbackService, MsgType } from './feedback.service';
// import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { HttpFunctionResult, HttpService, ServerInformations } from './http.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
// import { Network } from '@capacitor/network';
import { StorageManagerService } from './storage-manager.service';
import * as moment from 'moment';
import { v4 as uuid } from 'uuid';

export interface OfflineStateData{
  userId: number,
  offlineId?: string,
  state: number,
  startTime: Date | string,
  startDate?: Date
}

@Injectable({
  providedIn: 'root'
})
export class OfflineModeService {

  public active: boolean = true
  public _isOfflineMode: boolean = false
  public _offlineStateData: OfflineStateData[] = []

  private offlineModeRequestTimer

  public set isOfflineMode(val: boolean) {
    if(!this._isOfflineMode && val)
      this.offlineModeRequestTimer = setInterval(async () => await this.checkConnectionAndFetchData(), 35000)
    else if(this._isOfflineMode && !val)
      clearInterval(this.offlineModeRequestTimer)
    this._isOfflineMode = val
  }

  public get isOfflineMode(): boolean {
    if(!this.http.serverVersionGreaterThan("1.1.0") || !this.active) return false
    return this._isOfflineMode
  }

  public setCurrentTime: (stateData: OfflineStateData) => void

  constructor(
    private platform: Platform,
    private feedback: FeedbackService,
    private http: HttpService,
    private storageManagerSrv: StorageManagerService,
  ) {
    this.subscribeToNetworkChanges()
  }

  public async loadOfflineModeSettings() {
    this.active = await this.storageManagerSrv.getItem<boolean>("OFFLINE_MODE_ACTIVE", true)
    this._offlineStateData = await this.storageManagerSrv.getItem<OfflineStateData[]>("OFFLINE_STATE_DATA", [])
    if(this._offlineStateData.length > 0)
      this.setCurrentTime(this._offlineStateData[this._offlineStateData.length - 1])
  }

  public async addOfflineStateData(data: OfflineStateData) {
    this._offlineStateData.push({...data, offlineId: uuid()})
    await this.storageManagerSrv.setItem<OfflineStateData[]>("OFFLINE_STATE_DATA",this._offlineStateData)
    await this.feedback.showToast("Zeit wurde im Offline-Modus gespeichert.", 800)
  }



  private async subscribeToNetworkChanges() {
    // Network.addListener('networkStatusChange', async () => await this.checkConnectionAndFetchData())
  }


  private async checkConnectionAndFetchData() {
    const result: HttpFunctionResult<ServerInformations> = await this.http.GET({ path: "" , handleError: false})
    if(result.success){
      this.http.serverInformations = result.data
      if(this.isOfflineMode) await this.feedback.showToast("Zeiterfassung ist wieder Online.")
      this.isOfflineMode = false
      await this.fetchOfflineData()
      return true
    }else{
      this.isOfflineMode = true
      return false
    }
  }

  private isFetchWorking: boolean = false
  public async fetchOfflineData() {

    if(this.isFetchWorking) return
    let data: OfflineStateData[] = (await this.storageManagerSrv.getItem<OfflineStateData[]>("OFFLINE_STATE_DATA", []))
    if (data.length === 0) return

    this.isFetchWorking = true
    
    await this.feedback.showStatusLoading("Offline-Daten übertragen...")

    const resp: HttpFunctionResult<OfflineStateData> = await this.http.POST({path: "userstate/offlinedata", body: data})
    await this.feedback.hideStatusLoading()
    if(resp.success){
      this._offlineStateData = []
      await this.storageManagerSrv.setItem<OfflineStateData[]>("OFFLINE_STATE_DATA", [])
      this.isFetchWorking = false
      this.setCurrentTime(resp.data as OfflineStateData)
    }else{
      //Fehler beim Senden der Daten
      this._offlineStateData = []
      await this.storageManagerSrv.setItem<OfflineStateData[]>("OFFLINE_STATE_DATA", [])
      this.isFetchWorking = false
      this.isOfflineMode = true
    }
  }
}
