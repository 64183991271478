import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FeedbackService, VibrateType } from 'src/app/services/feedback.service';
import { GlobalSettingsService } from 'src/app/services/global-settings.service';

@Component({
  selector: 'app-error-report-confirm',
  templateUrl: './error-report-confirm.page.html',
  styleUrls: ['./error-report-confirm.page.scss'],
})
export class ErrorReportConfirmPage implements OnInit {

  public sentryEnabled: boolean = false

  constructor(
    private modalCtrl: ModalController,
    private feedback: FeedbackService,
    public settings: GlobalSettingsService
  ) { 
    this.sentryEnabled = settings.sentryEnabled
  }

  ngOnInit() {
  }

  public async back() {
    await this.modalCtrl.dismiss()
  }

  public async changeSentry() {
    this.feedback.vibrate(VibrateType.medium)
    await this.settings.setEntryEnabled(this.sentryEnabled)
  } 
 
}
