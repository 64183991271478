import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpFunctionResult, HttpService } from './http.service';
import * as moment from 'moment'
import { Subscription } from 'rxjs';
import { TimeService } from './time.service';
import { State, StatusService } from './status.service';
import { StorageManagerService } from './storage-manager.service';
import { SetupPage } from '../pages/setup/setup.page';
import { FeedbackService } from './feedback.service';
import { Platform } from '@ionic/angular';
import { Role } from './auth.service';
import { TerminalService } from './terminal.service';
import { NotificationProvider } from './notification';
import { NativeService } from './native.service';
import { StempelService } from './stempel.service';
import { OfflineModeService, OfflineStateData } from './offline-mode.service';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class UsersService {


  private _worktime: number = 0 
  private _activeState: State = null
  private _userImage: string = ""
  private tabResumeSub: Subscription

  constructor(
    private http: HttpService,
    private timeSrv: TimeService,
    private storageManager: StorageManagerService,
    private feedback: FeedbackService,
    private stateSrv: StatusService,
    private nativeSrv: NativeService,
    private stempSrv: StempelService,
    private notificationSrv: NotificationProvider,
    private platform: Platform,
    private offlineModeSrv: OfflineModeService,
    private terminalSrv: TerminalService,
    private router: Router
  ) {
    this.platform.ready().then(async ()=> setTimeout(async () =>  await this.openSetupAndLoadData(), 100)) 
    this.timeSrv.globalTime.subscribe(()=>{ 
      if(!this._activeState?.isWorkingTime) return
      this._worktime++
    })
    this.offlineModeSrv.setCurrentTime = (stateData: OfflineStateData)=>{
      this._activeState = Object.assign({},{...this.stateSrv.states.find(s => s.id == stateData?.state), startDate: stateData?.startDate ? moment(stateData.startDate) : null}) || {id: -1, isWorkingTime: false}
      this._activeState.startDate = moment(stateData.startTime)
      // setTimeout(async () => {await this.getCurrentState()}, 1000);
    }
  }  
   
  public get worktime(): number{
    return this._worktime
  } 

  public set worktime(time: number){ 
    this._worktime = time   
  } 

  public get activeState(): State{
    return this._activeState
  }

  public get formattedWorktime(): string{
    return moment.utc(this._worktime * 1000).format("HH:mm:ss")
  }

  public get userName():{firstName: string, lastName: string}{
    return {
      firstName: this.http.decodedJWT?.firstName,
      lastName: this.http.decodedJWT?.lastName
    }
  }

  public get userImage(): string{
    return this._userImage
  }

  public get userRights(): Role[]{
    return this.http.decodedJWT?.roles
  }

  public get userId(): number{
    return this.http.decodedJWT?.id || this.http.decodedJWT?.sub
  }

  public async getWorktime(): Promise<HttpFunctionResult<any>>{
    const result: HttpFunctionResult<{workingtime: number}> = await this.http.GET({path: "user/worktime"})
    if(!result.success) return {success: false}
    this._worktime = result.data.workingtime
    return {success: true}
  }
  
  public async getCurrentState(): Promise<HttpFunctionResult<any>>{
    const response: HttpFunctionResult<{state: number, startDate: Date}> = await this.http.GET({path: "user/state"})
    if(!response.success) return {success: false}
    this._activeState = Object.assign({},{...this.stateSrv.states.find(s => s.id == response.data.state), startDate: response.data?.startDate ? moment(response.data.startDate) : null}) || {id: -1, isWorkingTime: false}
    return {success: true}
  }
  
  public async setNewState(state: State): Promise<HttpFunctionResult<any>>  {
    return new Promise(async resolve=>{
      const oldState: State = Object.assign({}, this._activeState) 
      this._activeState = state
      const result: HttpFunctionResult<any> = await this.stempSrv.setNewUserState({state: state.id, userId: this.userId})

      if(!result.success){
        this._activeState = oldState
        return resolve({success: false})
      } 

      if(this.http.serverVersionGreaterThan("1.0.7"))
        this._activeState.startDate = moment() 
      
      this.notificationSrv.schedule(state.id)
      resolve({success: true})

    }) 
  }

  public async loadProfileImage(): Promise<HttpFunctionResult<any>>  {

    const result: HttpFunctionResult<{image: any}> = await this.http.GET({path: "user/image"})
    if(!result.success) return {success: false}

    const image = result.data?.image

    if(image){
      const chunkSize = 8192; // Die Größe jedes Teilstücks (kann angepasst werden)
      const imageData = new Uint8Array(image.data);
      let base64Chunks = [];

      for (let i = 0; i < imageData.length; i += chunkSize) {
        const chunk = imageData.subarray(i, i + chunkSize);
        const chunkString = String.fromCharCode.apply(null, chunk);
        base64Chunks.push(chunkString);
      }

      this._userImage = `data:image/jpeg;base64,${btoa(base64Chunks.join(''))}`
    } else this._userImage = ""

    return {success: true}

  }

  //#region Setup und Daten laden

  //Daten laden und gegebenfalls Setup öffnen wenn noch nicht eingerichtet
  public async openSetupAndLoadData(){
    if(await this.storageManager.getItem<string>("BASEURL", "") && await this.storageManager.getItem<string>("REFRESH_TOKEN", "")){
      await this.loadUserData()
      await this.nativeSrv.connectWidget(true)
    }
    else{
      await this.feedback.openModal(SetupPage, ()=>{}, async (result: HttpFunctionResult<any>)=>{
        if(!result.success) this.openSetupAndLoadData()
        else await this.loadUserData()
      }, false)
    }
  }

  public async loadUserData(withLoading: boolean = true){

    //Daten laden für Status Seite
    // alert(withLoading)
    if(withLoading) await this.feedback.updateLoading("Status wird geladen...")
    const statesResult: HttpFunctionResult<State[]> = await this.stateSrv.getAllMobileStates()
  
    if(!statesResult.success) 
      return await this.feedback.hideLoading()

    //Liste der Funktionen die ausgeführt werden sollen bei laden
    const loadDataPromisses: Promise<any>[] = [ 
       this.getCurrentState(),
       this.getWorktime(),
      //  this.timelineSrv.getTimeline(true)
    ]

    this.loadProfileImage()

    //Terminal Benutzer und Status setzen wenn Terminal Rechte da sein
    // if(this.userRights.includes(Role.TERMINAL)) loadDataPromisses.push(this.terminalSrv.loadTerminalData())

    //Alle Promises Ausführen
    await Promise.all(loadDataPromisses)

    //Items für Notifications setzen
    statesResult.data.forEach(item => this.notificationSrv.notifications.push({
      id: item.id,
      bezeichnung: item.description,
      mobil: item.mobile
    }))
    

    //Socket events registrieren 
    // this.sockets.initSocket(this.userId, this.userRights.includes(Role.TERMINAL), (event: SocketEvent)=>{
    //   if(event.type != socketEventType.USER_STATE_CHANGED) return
    //   this._activeState = this.stateSrv.states.find((s: State)=> s.id == event.data.state)
    // })

    //Neuladen wenn App in Hintergrund geht und wieder in Vordergrund kommt
    if(this.tabResumeSub) this.tabResumeSub.unsubscribe()
    this.tabResumeSub = this.platform.resume.subscribe(async (ev)=>{
      if(this.router.url == "/tabs/terminal-status") return
      await this.feedback.hideLoading()
      await this.loadUserData()
    }) 

    await this.feedback.hideLoading()
  } 
   
  //#endregion
}
