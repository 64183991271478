<ion-header>
  <ion-toolbar mode="md">
    <ion-title slot="start">{{stempSrv.currentUser.firstName}} {{stempSrv.currentUser.lastName}}</ion-title>
    <ion-buttons slot="end" *ngIf="stempSrv.fromNFC && http.serverVersionGreaterThan('1.1.0')">
      <ion-button slot="icon-only" (click)="showWorkTime()">
        <ion-icon name="fa-information"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<!-- scroll-y="false" -->
<ion-content [ngStyle]="{'font-size': fontSize + 'px'}" scroll-y="false" >
  <!-- <div id="loading" *ngIf="!loaded">
    <ion-spinner name="dots"></ion-spinner>
  </div> -->

  <section style="height: 100% ;">

    <!-- GEHEN -->
    <div class="full go bttn-icon" *ngIf="isWorking" (click)="setState(-1)" style="height: calc(50% - 7px);">
      <svg [ngStyle]="{'height.px': fontSize}" style="height: f" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M256 48c0 26.5-21.5 48-48 48s-48-21.5-48-48s21.5-48 48-48s48 21.5 48 48zM126.5 199.3c-1 .4-1.9 .8-2.9 1.2l-8 3.5c-16.4 7.3-29 21.2-34.7 38.2l-2.6 7.8c-5.6 16.8-23.7 25.8-40.5 20.2s-25.8-23.7-20.2-40.5l2.6-7.8c11.4-34.1 36.6-61.9 69.4-76.5l8-3.5c20.8-9.2 43.3-14 66.1-14c44.6 0 84.8 26.8 101.9 67.9L281 232.7l21.4 10.7c15.8 7.9 22.2 27.1 14.3 42.9s-27.1 22.2-42.9 14.3L247 287.3c-10.3-5.2-18.4-13.8-22.8-24.5l-9.6-23-19.3 65.5 49.5 54c5.4 5.9 9.2 13 11.2 20.8l23 92.1c4.3 17.1-6.1 34.5-23.3 38.8s-34.5-6.1-38.8-23.3l-22-88.1-70.7-77.1c-14.8-16.1-20.3-38.6-14.7-59.7l16.9-63.5zM68.7 398l25-62.4c2.1 3 4.5 5.8 7 8.6l40.7 44.4-14.5 36.2c-2.4 6-6 11.5-10.6 16.1L54.6 502.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L68.7 398z"/></svg>
      {{ btnTitle }}
    </div>

    <!-- KOMMEN -->
    <div full class="full come bttn-icon" *ngIf="!isWorking" (click)="setState(0)">
      <svg [ngStyle]="{'height.px': fontSize}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>
      {{ btnTitle }}
    </div>
    
    <!-- PAUSE -->
    <div *ngIf="isWorking" class="bigbtn" (click)="setState(1)" style="background-color: #e86060; height: calc(50% - 7px)">
      <svg [ngStyle]="{'height.px': fontSize}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M233.3 0H264c13.3 0 24 10.7 24 24s-10.7 24-24 24H233.3c-3.7 0-6.9 2.6-7.8 6.2L208.2 128H320c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H158.9l20-84.8C184.8 17.9 207.4 0 233.3 0zM60.3 468L40 224H292.6c-18.4 19.1-28 37.5-32.2 47c-3.4 7.7-4.4 15.3-4.4 21.5c0 17.8 7.9 33.9 20.3 44.8C263.8 348.9 256 365.5 256 384c0 16.7 6.4 32 17 43.4c-10.4 8.8-17 21.9-17 36.6v8c0 12.7 3.3 24.7 9.1 35c-6.4 3.2-13.6 5-21.3 5H108.2c-25 0-45.8-19.1-47.8-44zM640 292.5c0 15.2-12.3 27.5-27.5 27.5H315.5c-15.2 0-27.5-12.3-27.5-27.5c0-3 .5-5.9 1.7-8.6C297.8 265.5 338 192 464 192s166.2 73.5 174.3 91.9c1.2 2.7 1.7 5.7 1.7 8.6zM384 272c8.8 0 16-7.2 16-16s-7.2-16-16-16s-16 7.2-16 16s7.2 16 16 16zm96-32c0-8.8-7.2-16-16-16s-16 7.2-16 16s7.2 16 16 16s16-7.2 16-16zm64 32c8.8 0 16-7.2 16-16s-7.2-16-16-16s-16 7.2-16 16s7.2 16 16 16zM320 352H608c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32zm-16 96H624c8.8 0 16 7.2 16 16v8c0 22.1-17.9 40-40 40H328c-22.1 0-40-17.9-40-40v-8c0-8.8 7.2-16 16-16z"/></svg>
      {{ pauseTitle }}
    </div>  
 
  </section>


</ion-content> 


<ion-footer [ngStyle]="{'font-size': fontSize + 'px'}">
  <ion-toolbar>
    <section >
      <!-- GEHEN KLEIN -->
      <div *ngIf="!isWorking && !isAbsent"  class="bigbtn" (click)="setState(-1)" style="background-color: #e86060;">
        <svg [ngStyle]="{'height.px': fontSize}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M256 48c0 26.5-21.5 48-48 48s-48-21.5-48-48s21.5-48 48-48s48 21.5 48 48zM126.5 199.3c-1 .4-1.9 .8-2.9 1.2l-8 3.5c-16.4 7.3-29 21.2-34.7 38.2l-2.6 7.8c-5.6 16.8-23.7 25.8-40.5 20.2s-25.8-23.7-20.2-40.5l2.6-7.8c11.4-34.1 36.6-61.9 69.4-76.5l8-3.5c20.8-9.2 43.3-14 66.1-14c44.6 0 84.8 26.8 101.9 67.9L281 232.7l21.4 10.7c15.8 7.9 22.2 27.1 14.3 42.9s-27.1 22.2-42.9 14.3L247 287.3c-10.3-5.2-18.4-13.8-22.8-24.5l-9.6-23-19.3 65.5 49.5 54c5.4 5.9 9.2 13 11.2 20.8l23 92.1c4.3 17.1-6.1 34.5-23.3 38.8s-34.5-6.1-38.8-23.3l-22-88.1-70.7-77.1c-14.8-16.1-20.3-38.6-14.7-59.7l16.9-63.5zM68.7 398l25-62.4c2.1 3 4.5 5.8 7 8.6l40.7 44.4-14.5 36.2c-2.4 6-6 11.5-10.6 16.1L54.6 502.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L68.7 398z"/></svg>
        Gehen
      </div>

      <!-- PAUSE -->
      <div *ngIf="!isPaused && !isAbsent && !isWorking" class="bigbtn" (click)="setState(1)" style="background-color: #e86060;">
        <svg [ngStyle]="{'height.px': fontSize}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M233.3 0H264c13.3 0 24 10.7 24 24s-10.7 24-24 24H233.3c-3.7 0-6.9 2.6-7.8 6.2L208.2 128H320c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H158.9l20-84.8C184.8 17.9 207.4 0 233.3 0zM60.3 468L40 224H292.6c-18.4 19.1-28 37.5-32.2 47c-3.4 7.7-4.4 15.3-4.4 21.5c0 17.8 7.9 33.9 20.3 44.8C263.8 348.9 256 365.5 256 384c0 16.7 6.4 32 17 43.4c-10.4 8.8-17 21.9-17 36.6v8c0 12.7 3.3 24.7 9.1 35c-6.4 3.2-13.6 5-21.3 5H108.2c-25 0-45.8-19.1-47.8-44zM640 292.5c0 15.2-12.3 27.5-27.5 27.5H315.5c-15.2 0-27.5-12.3-27.5-27.5c0-3 .5-5.9 1.7-8.6C297.8 265.5 338 192 464 192s166.2 73.5 174.3 91.9c1.2 2.7 1.7 5.7 1.7 8.6zM384 272c8.8 0 16-7.2 16-16s-7.2-16-16-16s-16 7.2-16 16s7.2 16 16 16zm96-32c0-8.8-7.2-16-16-16s-16 7.2-16 16s7.2 16 16 16s16-7.2 16-16zm64 32c8.8 0 16-7.2 16-16s-7.2-16-16-16s-16 7.2-16 16s7.2 16 16 16zM320 352H608c17.7 0 32 14.3 32 32s-14.3 32-32 32H320c-17.7 0-32-14.3-32-32s14.3-32 32-32zm-16 96H624c8.8 0 16 7.2 16 16v8c0 22.1-17.9 40-40 40H328c-22.1 0-40-17.9-40-40v-8c0-8.8 7.2-16 16-16z"/></svg>
        {{ pauseTitle }}
      </div>  
  
      <!-- MEHR -->
      <div class="bigbtn" (click)="choose()">
        <svg [ngStyle]="{'height.px': fontSize}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z"/></svg>
        Mehr
      </div>
  
      <!-- ABBRECHEN -->
      <div id="cancelbutton" class="bigbtn" (click)="cancel()">
        <svg [ngStyle]="{'height.px': fontSize}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg>
        Abbrechen
      </div>
    </section>
  </ion-toolbar>
</ion-footer>