import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageManagerService } from './storage-manager.service';

@Injectable({
  providedIn: 'root'
})
export class DarkmodeService {

  private _isDarkmode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor(
    private storageManager: StorageManagerService
  ) { }

  public get isDarkmode(): Observable<boolean>{
    return this._isDarkmode.asObservable()
  }

  public get isDarkModeValue(): boolean{
    return this._isDarkmode.value
  }

  public async setIsDarkmode(isDarkmode: boolean){
    this._isDarkmode.next(isDarkmode)
    const toggle = document.querySelector('#themeToggle');
    // Listen for the toggle check/uncheck to toggle the dark class on the <body>
    document.body.classList.toggle('dark', isDarkmode);
    document.body.classList.toggle('light', !isDarkmode);
    await this.storageManager.setItem<boolean>("darkmode", isDarkmode) 
  }

}
